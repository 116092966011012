exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-club-contentful-club-slug-jsx": () => import("./../../../src/pages/club/{contentfulClub.slug}.jsx" /* webpackChunkName: "component---src-pages-club-contentful-club-slug-jsx" */),
  "component---src-pages-clubs-jsx": () => import("./../../../src/pages/clubs.jsx" /* webpackChunkName: "component---src-pages-clubs-jsx" */),
  "component---src-pages-feedback-jsx": () => import("./../../../src/pages/feedback.jsx" /* webpackChunkName: "component---src-pages-feedback-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-interests-jsx": () => import("./../../../src/pages/interests.jsx" /* webpackChunkName: "component---src-pages-interests-jsx" */),
  "component---src-pages-map-jsx": () => import("./../../../src/pages/map.jsx" /* webpackChunkName: "component---src-pages-map-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-scavenger-hunt-jsx": () => import("./../../../src/pages/scavenger-hunt.jsx" /* webpackChunkName: "component---src-pages-scavenger-hunt-jsx" */),
  "component---src-pages-schedule-jsx": () => import("./../../../src/pages/schedule.jsx" /* webpackChunkName: "component---src-pages-schedule-jsx" */),
  "component---src-pages-settings-jsx": () => import("./../../../src/pages/settings.jsx" /* webpackChunkName: "component---src-pages-settings-jsx" */),
  "component---src-pages-sponsors-jsx": () => import("./../../../src/pages/sponsors.jsx" /* webpackChunkName: "component---src-pages-sponsors-jsx" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

