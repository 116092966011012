module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Somerset County 4-H Fair","short_name":"SC 4H Fair","start_url":"/","related_applications":[{"platform":"webapp","url":"https://sc4hfair.app/manifest.json"}],"background_color":"#009959","theme_color":"#009959","display":"standalone","icon":"src/images/app-icon.svg","icon_options":{"purpose":"any maskable"},"legacy":false,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"7c7aa7337382ff7235b660ff0efce943"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-QYEGYTKG7B"]},
    },{
      plugin: require('../../../var/task/node_modules/@vercel/gatsby-plugin-vercel-analytics/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
